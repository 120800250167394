import axios from "axios";
import { server } from "../../server";

// Load miner
export const loadMiner = () => async (dispatch) => {
  try {
    dispatch({
      type: "LoadMinerRequest",
    });

    // Retieve the JWT token from localStorage
    const minerToken = localStorage.getItem('miner_Token');

    // if no token is found, dispatch failure and return
    if(!minerToken){
      dispatch({
        type: "LoadMinerFail",
        payload: "No token found. Please re-enter mine."
      });
      return;
    }

     // Make API call to get the miner data
    const { data } = await axios.get(`${server}/miner/getMiner`, {
      headers: {
        Authorization: `Bearer ${minerToken}`, // Attache token to the authorization header
      },
    });

    console.log("Miner Data: ", data.miner);  // Check what data is returned from the API

    // Dispatch success action with miner data
    dispatch({
      type: "LoadMinerSuccess",
      payload: data.miner,
    });
  } catch (error) {
    dispatch({
      type: "LoadMinerFail",
      payload: error.response?.data.message || error.message,
    });
  }
};



// get all users --- Open
export const getAllUsersOpen = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllUsersOpenRequest",
    });

    const { data } = await axios.get(`${server}/miner/all-miners`, {
      withCredentials: true,
    });

    dispatch({
      type: "getAllMinersOpenSuccess",
      payload: data.miners,
    });
  } catch (error) {
    dispatch({
      type: "getAllMinersOpenFailed",
      payload: error.response.data.message,
    });
  }
};
