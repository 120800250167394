import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isMiner: false,
  loading: false,  // To track the loading state of user authentication
  rminer: null,      // Store miner data when successfully authenticated
  error: null,     // Store error message if authentication fails
};

export const minerReducer = createReducer(initialState, {
  LoadMinerRequest: (state) => {
    state.loading = true;
  },
  LoadMinerSuccess: (state, action) => {
    state.loading = false;
    state.rminer = action.payload;
    state.isMiner = true;
  },
  LoadMinerFail: (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.isMiner = false;
    state.rminer = null;
  },  
  clearErrors: (state) => {
    state.error = null;
  },
  clearMessages: (state) => {
    state.successMessage = null;
  },

  // get all miners 
  getAllMinersOpenRequest: (state) => {
    state.minersLoading = true;
  },
  getAllMinersOpenSuccess: (state,action) => {
    state.minersLoading = false;
    state.rminers = action.payload;
  },
  getAllMinersOpenFailed: (state,action) => {
    state.minersLoading = false;
    state.error = action.payload;
  },
 });
