import { React, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "../../styles/styles";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import Spinner from "../Spinner/Spinner";
import tcoin from '../../Assests/eTSTImg/etstcoin.png';
import coin from '../../Assests/eTSTImg/coin.png';
import lol from '../../Assests/eTSTImg/lol.gif';


const LoginMiner = () => {
  const navigate = useNavigate();
  const [telegID, setTelegID] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    await axios.post(
      `${server}/miner/app.tst-token/login-miner`,
      {
        username: telegID,
      },
      { withCredentials: true }
    )
    .then((res) => {
      setIsLoading(false);
  
      // Assuming the response contains the token directly in res.data
      const { minerToken } = res.data;  // Corrected: directly extract minerToken from res.data
      console.log("minerToken Data: ");
      console.log(res.data);
  
      if (minerToken) {
        localStorage.setItem("miner_Token", minerToken);
        toast.success(`Welcome back ${telegID}`);
        navigate("/"); // Redirect to the appropriate page
        window.location.reload(true);  // Reload to update the state
      } else {
        toast.error("Token not received from server");
      }
    })
    .catch((err) => {
      setIsLoading(false);
      toast.error(err?.response?.data?.message || "Something went wrong");
    });
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="min-h-screen w-full relative flex flex-col justify-center"
               style={{ background: "linear-gradient(#000, #35063E, rgb(132, 82, 129))" }}>
                
            <div className="mt-2 sm:mx-auto sm:w-[80%] sm:max-w-md p-4">
              <div className="py-4 px-4">
                <div>
                  <button className="group relative w-full flex justify-center">
                    <img src={lol} width='200px' alt="" className="mr-2" />
                  </button>
                </div>
              </div>
            </div>
            <div className="fixed bottom-0 left-0 w-full px-4 pb-4 z-10">
              <div className="w-full flex justify-between gap-2 mt-4">
                <div className="flex-grow flex items-center max-w-90 text-sm font-[300]">
                  <div className="w-full bg-[#000] bg-opacity-70 py-4 px-2 rounded-2xl justify-around">
                  <h5 className="mb-2 text-[#fff] w-full text-center">Not yet a miner? <Link to="https://t.me/etst_taps_bot" target="_blank"><span className="text-[yellow] cursor-pointer">SignUp on Telegram</span></Link> to get your miner name.</h5>
                    <div className="flex flex-col items-center gap-1">
                      <form className="space-y-6" onSubmit={handleSubmit}>
                        <div>
                          <div className="flex mt-1">
                            <input
                              type="text"
                              id="telegID"
                              name="telegID"
                              required
                              placeholder="enter miner name"
                              value={telegID}
                              onChange={(e) => setTelegID(e.target.value)}
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-700 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            />
                            <button type="submit" className="text-sm bg-[teal] text-[#fff] w-[100px] px-2 py-1 rounded-md overflow-x-hidden overflow-y-auto text-center">Enter</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default LoginMiner;
